import $ from 'jquery';

$(document).ready(function() {
		
	//HEADER

	$(window).scroll(function() {
		var sticky = $('#header'),
		scroll = $(window).scrollTop();
	
		if (scroll >= 100) { 
			sticky.addClass('fixed'); 
		} else { 
			sticky.removeClass('fixed');
			
		}
	});

	$("#menu-toggle").click(function(e) {
	    e.preventDefault();
	    $("#sidebar-wrapper").toggleClass("toggled");
	});
	
	$("#sidebar-wrapper .close").click(function(e) {
	    e.preventDefault();
	    $("#sidebar-wrapper").removeClass("toggled");
	});

	$(".search_mobile").click(function(e) {
		e.preventDefault();
		$(".search_bar").fadeToggle(300);
	});

	$(".search_bar .close").click(function(e) {
		e.preventDefault();
		$(".search_bar").slideUp(500);
	});

	function setMainPaddingTop() {
		if ($(document.body).hasClass('home')) return;
		$('main').css('paddingTop', $('#header').height()+50);
	}
	setMainPaddingTop();
	$(window).resize(setMainPaddingTop);

});